import React, { useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";
import Seo from "../components/seo/seo";
const ReferToAFriendPage = (props) => {
  const intl = useIntl();
  useEffect(() => {
    let splittedURL = props.location.pathname.split("/").filter((item) => item !== "");
    let userLanguage = splittedURL[0];
    window.location.replace(`${process.env.GATSBY_DASHBOARD_URL}/${userLanguage}/register${props.location.search}`)
  }, []);

  return (
    <>
      <Seo
        title={intl.formatMessage({ id: "JOIN_META_TITLE" })}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: "JOIN_META_DESCRIPTION" })}
      />

    </>
  );
};

export default ReferToAFriendPage;
